import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import {
  FaClock,
  FaPlus,
  FaPuzzlePiece,
  FaSchool,
  FaSlidersH,
  FaUsers,
} from "react-icons/fa";
import ResponsiveEmbed from "react-responsive-embed";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Container from "../components/container";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
  }
`;

const UiDesigner = ({ data }) => {
  return (
    <FunctionPageLayout title="UI Designer" headline="Dołącz do zespołu">
      <SEO
        title="UI Designer- oferta pracy"
        description="Producent oprogramowania dla firm ✅ Oferta pracy • UI Designer • Skontaktuj się z nami"
      />
      <br />
      <Container>
        <h2> Kim jesteśmy? </h2>
        <br />
        <p>
          Jesteśmy{" "}
          <strong> producentem i dostawcą oprogramowania dla biznesu</strong>{" "}
          działającym od 2002 roku. Pracujemy zarówno dla dużych,
          rozpoznawalnych firm, jak i dla mniejszych – dynamicznie rozwijających
          się przedsiębiorstw. Wspieramy klientów biznesowymi narzędzami, które
          umożliwiają realizację strategii, zapewniając tym samym lepsze i
          przyjemniejsze jutro. Nasz zespół składa się z około 40 specjalistów,
          których łączy wspólna pasja — technologie informacyjne.
        </p>
        <br />
        <br />
        <h2> Wartości, które wyznajemy:</h2>
        <br />
        <p>
          <ul>
            <li>
              {" "}
              stosujemy dobre praktyki zarządzania, a za główny miernik wartości
              uznajemy osiągane rezultaty,{" "}
            </li>
            <li> stawiamy na ciągłe samodoskonalenie, rozwój i edukację, </li>
            <li> do osiągania celów wykorzystujemy wiedzę i doświadczenie, </li>
            <li>
              {" "}
              traktujemy klientów i pracowników jako partnerów, z którymi
              wspólnie budujemy przyszłość,{" "}
            </li>
            <li> w codziennej pracy cenimy zaangażowanie i pasję, </li>
            <li>
              {" "}
              stawiamy na otwartą komunikację – jeśli coś nie działa, mówimy o
              tym głośno,{" "}
            </li>
            <li>
              {" "}
              nie jesteśmy korporacją – u nas wszyscy się znają i współpracują,{" "}
            </li>
            <li>
              {" "}
              stawiamy na pracę zespołową, bo sukces w naszej branży zależy od
              zaangażowania wielu osób,{" "}
            </li>
            <li>
              {" "}
              i… last but not least – bardzo lubimy technologie informatyczne!{" "}
            </li>
          </ul>
        </p>
      </Container>
      <h2
        style={{
          paddingBottom: 20,
          paddingTop: 30,
        }}
      >
        Tworzymy zgrany zespół
      </h2>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              To, co motywuje nas do codziennego działania to współpraca. <br />
              Każdego dnia wspólnie tworzymy projekty, rozwiązujemy problemy
              naszych klientów, świętujemy nasze sukcesy, świetnie się przy tym
              wszystkim bawiąc.
              <br />
              <strong>
                {" "}
                Przecież w dobrej atmosferze obowiązki stają się przyjemnością,
                prawda?
              </strong>
            </p>
          </div>
          <div className="text-block">
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/lLKbdB1I0xo"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>
      <center>
        {" "}
        <h2> Dlaczego warto z nami pracować? </h2>{" "}
      </center>
      <br />

      <div>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaClock size="60px" />}
            title="Stabilna firma o ugruntowanej pozycja na rynku"
          />
          <BenefitBlock
            icon={<FaUsers size="60px" />}
            title="40-osobowy zgrany zespół"
          />
          <BenefitBlock
            icon={<FaPuzzlePiece size="60px" />}
            title="Różnorodność projektów i technologii"
          />
        </BenefitBlockWrapper>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaSchool size="60px" />}
            title="Dzielimy się wiedzą i doświadczeniem"
          />
          <BenefitBlock
            icon={<FaSlidersH size="60px" />}
            title="Jesteśmy elastyczni i do każdego podchodzimy indywidualnie"
          />
          <BenefitBlock
            icon={<FaPlus size="60px" />}
            title="Oferujemy dodatkowe benefity"
          />
        </BenefitBlockWrapper>
      </div>

      <br />
      <h2> Oferta pracy </h2>
      <center>
        <h3>UI Designer</h3>
        <p> Praca w modelu hybrydowym</p>
      </center>
      <br />
      <p>
        <strong>
          Twoim celem będzie projektowanie atrakcyjnego, estetycznego wyglądu
          istniejących oraz nowych rozwiązań w obszarze CRM, e-commerce B2B oraz
          Services Management.
        </strong>
      </p>
      <br />
      <p>
        <strong>Czym będziesz się zajmować?</strong>
      </p>
      <p>
        <ul>
          <li>
            Przygotowywaniem mockupów oraz prezentowaniem koncepcji projektu.
          </li>
          <li>Projektowaniem pixel-perfect aplikacji mobilnych i webowych.</li>
          <li>
            Przygotowywaniem niezbędnej dokumentacji projektu na potrzeby
            dalszych prac.
          </li>
          <li>Weryfikowaniem trendów i planowaniem spójnej strategii UX/UI.</li>
          <li>Planowaniem nowych rozwiązań i funkcjonalności.</li>
          <li>Współpracą z Działem Rozwoju Oprogramowania.</li>
        </ul>
      </p>
      <br />
      <p>
        <strong> Jakiej osoby szukamy?</strong>
      </p>
      <p>
        <ul>
          <li>Estety</li>
          <li>Kreatywnej</li>
          <li>Z wyczuciem smaku</li>
          <li>Konkretnej</li>
          <li>Komunikatywnej</li>
          <li>Systematycznej i dokładnej</li>
          <li>Cierpliwej</li>
          <li>Myślącej logicznie</li>
          <li>Z pozytywną energią i nastawieniem do wyzwań</li>
        </ul>
      </p>
      <p>To Ty? Jeśli tak, to świetnie!</p>
      <br />
      <p>
        <strong>Do tego, jeżeli posiadasz takie umiejętności jak:</strong>
      </p>
      <p>
        <ul>
          <li>obsługa narzędzi do tworzenia mockupów np. figma,</li>
          <li>projektowanie mockupów zgodnie z najnowszymi trendami.</li>
        </ul>
      </p>
      <p>to mamy już prawie 100% sukces!</p>
      <br />
      <p>
        <strong>Dodatkowym plusem będzie:</strong>
      </p>
      <p>
        <ul>
          <li>znajomość Chakra UI.</li>
        </ul>
      </p>
      <br />
      <p>Spełniasz większość z podanych przez nas wymagań? Aplikuj!</p>
      <br />
      <p>
        {" "}
        <strong> Co zyskujesz dołączając do naszego zespołu? </strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            {" "}
            Lojalnego pracodawcę, partnera zainteresowanego długoterminową
            współpracą z Tobą.
          </li>
          <li>
            {" "}
            Ciekawą pracę w dynamicznej firmie w zespole młodych ludzi
            nastawionych na przyszłość i rozwój.
          </li>
          <li>
            {" "}
            Szansę na realizowanie własnych pomysłów, bo w naszej firmie
            stawiamy na innowacyjność wypływającą z zespołu.
          </li>
          <li>
            {" "}
            Wsparcie doświadczonego (i fajnego) zespołu, zainteresowanego grą do
            jednej bramki.
          </li>
          <li> Przyjazną atmosferę i spotkania integracyjne.</li>
          <li>
            {" "}
            Pracę hybrydową z biurem w centrum Poznania (3 min pieszo z Dworca
            Zachodniego).
          </li>
          <li> Dofinansowanie do karty Multisport.</li>
          <li> Pyszną kawę każdego dnia. 🙂</li>
          <li>
            {" "}
            Forma zatrudnienia do ustalenia indywidualnie (umowa zlecenie, UoP,
            kontrakt).
          </li>
        </ul>
      </p>

      <Container>
        <h2
          style={{
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          Przekonaliśmy Cię?
          <br />
          Zobacz, jak do nas dołączyć.{" "}
        </h2>{" "}
        <br />
        <GatsbyImage
          image={data.rekrutacja.childImageSharp.gatsbyImageData}
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            marginTop: 30,
          }}
          alt="rekrutacja"
        />
        <br />
        <br />
      </Container>
      <h2> Czekamy na Ciebie! </h2>
      <br />
      <br />
      <center>
        <p>
          Wyślij CV z dopiskiem <strong>„UI Designer”</strong> oraz zgodą na
          przetwarzanie danych osobowych w procesie rekrutacji na adres
          <strong> kariera@questy.pl </strong>{" "}
        </p>

        <p>
          {" "}
          Informujemy, że skontaktujemy się osobiście tylko z wybranymi
          kandydatami.{" "}
        </p>
      </center>
      <br />
      <br />
      <br />

      <p>Prosimy o zawarcie w CV klauzuli: </p>

      <p>
        „Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej
        ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
        prowadzonego przez QUESTY z siedzibą w Poznaniu zgodnie z ustawą z dnia
        29 sierpnia 1997 r. o ochronie danych osobowych (t.j. Dz. U. z 2016 r.,
        poz. 922)”. Jednocześnie wyrażam zgodę na przetwarzanie przez
        ogłoszeniodawcę moich danych osobowych na potrzeby przyszłych
        rekrutacji.”
      </p>
      <p>
        Informujemy, że Administratorem danych jest QUESTY z siedzibą w Poznaniu
        przy ul. Głogowskiej 31/33. Dane zbierane są dla potrzeb rekrutacji. Ma
        Pani/Pan prawo dostępu do treści swoich danych oraz ich poprawiania.
        Podanie danych w zakresie określonym przepisami ustawy z dnia 26 czerwca
        1974 r. Kodeks pracy oraz aktów wykonawczych jest obowiązkowe. Podanie
        dodatkowych danych osobowych jest dobrowolne.
      </p>
    </FunctionPageLayout>
  );
};

export default UiDesigner;
